export const APPDOWNLOADBANNERLINKS = {
  HOMEPAGE: 'https://hostelworld.onelink.me/IdiK/o4e0z8xk',

  WORLDWIDE: 'https://hostelworld.onelink.me/IdiK/zfz2inhe',

  CONTINENT: 'https://hostelworld.onelink.me/IdiK/0tz4sdmj',

  COUNTRY: 'https://hostelworld.onelink.me/IdiK/f5168gpb',

  AREA: 'https://hostelworld.onelink.me/IdiK/ck5odmzm',

  REGION: 'https://hostelworld.onelink.me/IdiK/24o49vyu',

  CITY: 'https://hostelworld.onelink.me/IdiK/pte4dvbo',

  DISTRICT: 'https://hostelworld.onelink.me/IdiK/0adndjf8',

  PROPERTY: 'https://hostelworld.onelink.me/IdiK/h8b6frh3',

  MENU: 'https://hostelworld.onelink.me/IdiK/l5wn8um8',

  GETTHEAPPPILL: 'https://hostelworld.onelink.me/IdiK/5ldn0n3k',
};
