<script setup>
import { commonValues } from '@/config/common';
import { useCurrencyStore } from '@/stores/currency';
import { useLanguageStore } from '@/stores/language';
import { onClickOutside } from '@vueuse/core';
import { WdsHeader } from '@wds/components';

const props = defineProps({
  singleBar: {
    type: Boolean,
    required: false,
    default: false,
  },
  alternativeBar: {
    type: Boolean,
    required: false,
    default: false,
  },
  showListPropertyPill: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isSingleBar = toRef(props, 'singleBar');
const myAccount = useAccount();
const {
  $tracking,
  $url,
  callHook,
} = useNuxtApp();
const { t } = useI18n();
const { useUrl } = useUrls();
const {
  isBelowMediumScreen,
  isLargeScreen,
  isMediumScreen,
} = useMq();
const languageStore = useLanguageStore();
const currencyStore = useCurrencyStore();
const runtimeConfig = useRuntimeConfig();
const envData = runtimeConfig?.public;

const isLoggedInMenuOpen = ref(false);
const loggedInMenu = ref(null);
const loggedInButton = ref(null);
const isHamMenuOpen = ref(false);
const hamBurguerMenu = ref(null);
const hamBurguerButton = ref(null);
const propertySignUpURL = ref('');
const HwHeader = ref(null);
const showOnMediumScreen = ref(false);
const mySearchFormRef = ref(null);

const hasLoggedInUser = toValue(myAccount.hasLoggedInUser);
const loggedInUser = toValue(myAccount.getLoggedInUser);
const loggedInMenuItems = myAccount.getLoggedInMenuItems;

// ## COMPUTED ##//
const showSearchForm = computed(() => {
  if (toValue(isMediumScreen)) {
    return toValue(isMediumScreen) && showOnMediumScreen.value && toValue(isSingleBar);
  }
  return toValue(isSingleBar);
});

onClickOutside(
  hamBurguerMenu,
  () => {
    if (!toValue(isBelowMediumScreen)) {
      toggleHamburguerMenu(false);
    }
  },
  { ignore: [hamBurguerButton] },
);

onClickOutside(loggedInMenu, () => toggleLoggedInMenu(false), { ignore: [loggedInButton] });

function toggleHamburguerMenu(toggle) {
  isHamMenuOpen.value = toggle === undefined ? !isHamMenuOpen.value : toggle;
}

function toggleLoggedInMenu(toggle) {
  isLoggedInMenuOpen.value = toggle === undefined ? !isLoggedInMenuOpen.value : toggle;
}

function openLanguagePicker() {
  languageStore.toggleLanguagePicker(true);
  if (!toValue(isBelowMediumScreen)) {
    toggleLoggedInMenu(false);
    toggleHamburguerMenu(false);
  }
}

function openCurrencyPicker() {
  currencyStore.toggleCurrencyPicker(true);
  if (!toValue(isBelowMediumScreen)) {
    toggleLoggedInMenu(false);
    toggleHamburguerMenu(false);
  }
}
function toggleSearchForm() {
  if (toValue(isMediumScreen)) {
    showOnMediumScreen.value = !showOnMediumScreen.value;
  } else {
    callHook('search-form:open', 'destination');
  }
}

function handleLoggedInMenuItemClick(menuItem) {
  if (menuItem === 'logout') {
    $tracking?.onLogoutClick();

    setTimeout(() => {
      const base64QueryParam = btoa(window.location.href);
      const logoutParams = { 'return-to': base64QueryParam };
      navigateTo(useUrl.getLogoutPageUrlDynamic(logoutParams), { external: true });
    }, 100);
  }
}

function handleLogoCLick() {
  $url.setHref(useUrl.getStaticBaseURL());
}

function redirectHostelSignup() {
  if (['dev', 'test'].includes(envData.RELEASE_ENV)) {
    propertySignUpURL.value = t('t_HOMETESTURLHOSTELSIGNUP');
  } else if (envData.RELEASE_ENV === 'staging') {
    propertySignUpURL.value = t('t_HOMESTAGINGURLHOSTELSIGNUP');
  } else {
    propertySignUpURL.value = t('t_HOMEURLHOSTELSIGNUP');
  }
  $url.setHref(propertySignUpURL.value, true);
  $tracking.onHostelSignupInteraction({ parameter1: 'new button' });
}

async function handleLoginClick() {
  if (hasLoggedInUser) {
    toggleLoggedInMenu();
  } else {
    const base64QueryParam = btoa(window.location.href);
    const authUrl = `${useUrl.getPwaLangBaseUrl()}${commonValues.HOSTELWORLD_LOGIN_URL}?return-to=${base64QueryParam}`;

    $tracking?.onLoginClick();
    setTimeout(() => {
      navigateTo(authUrl, { external: true });
    }, 100);
  }
}
onMounted(() => {
  hamBurguerButton.value = HwHeader.value.$refs.WdsHeaderHamburgerPill;
  loggedInButton.value = HwHeader.value.$refs.WdsHeaderLoginPill;
});
</script>

<template>
  <WdsHeader
    ref="HwHeader"
    class="hw-header"
    :small-logo="isSingleBar"
    :search-pill="!toValue(isLargeScreen) && isSingleBar"
    :list-property-pill="toValue(isLargeScreen) && !isSingleBar && showListPropertyPill"
    :list-property-text="$t('t_REGISTERPROPERTY')"
    :currency-pill="!toValue(isBelowMediumScreen)"
    :selected-currency="currencyStore?.getCurrentCurrency?.code"
    :selected-language="languageStore?.getCurrentLanguage?.name"
    :language-pill="!toValue(isBelowMediumScreen)"
    :user-data="loggedInUser"
    :back-button="false"
    :is-floating="alternativeBar"
    @logo-click="handleLogoCLick"
    @currency-pill-click="openCurrencyPicker"
    @language-pill-click="openLanguagePicker"
    @list-property-pill-click="redirectHostelSignup"
    @user-pill-click="handleLoginClick"
    @menu-pill-click="toggleHamburguerMenu"
    @search-pill-click="toggleSearchForm"
  >
    <template #header-slot>
      <CommonHwSearchForm
        v-show="showSearchForm"
        ref="mySearchFormRef"
        class="searchform"
        :hide-on-small-screen="isSingleBar"
      />
    </template>
  </WdsHeader>
  <nav>
    <div class="nav-buttons-wrapper">
      <CommonHwNavBarHwLoginMenu
        ref="loggedInMenu"
        :has-logged-in-user="hasLoggedInUser"
        :logged-in-user="loggedInUser"
        :logged-in-menu-items="loggedInMenuItems"
        :is-open="isLoggedInMenuOpen"
        @close-menu="isLoggedInMenuOpen = false"
        @handle-logged-in-menu-item-click="handleLoggedInMenuItemClick"
      />
      <CommonHwNavBarHwHamburguerMenu
        ref="hamBurguerMenu"
        :is-open="isHamMenuOpen"
        @close-menu="isHamMenuOpen = false"
        @open-language-picker="openLanguagePicker"
        @open-currency-picker="openCurrencyPicker"
      />
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.hw-header {
  padding: $wds-spacing-m $wds-spacing-l;

  @include desktop {
    padding: $wds-spacing-m wds-rem(40px);
  }

  :deep(header) {
    background-color: unset;
  }

  .searchform {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

nav {
  position: relative;
  display: grid;
  grid-template-areas: 'hw-brand nav-buttons';
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: $wds-spacing-s $wds-spacing-l;
  width: 100%;

  .nav-buttons-wrapper {
    grid-area: nav-buttons;
    display: flex;
    gap: $wds-spacing-s;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
