import { useCurrencyStore } from '@/stores/currency';

export function useApiContinents() {
  const {
    $api,
    $i18n,
  } = useNuxtApp();
  const { locale } = $i18n;
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getAllContinents = async (propertyType) => {
    let result = ref(null);

    const callKey = `getAllContinents${locale?.value}${propertyType}${userCurrency?.code}`;
    const endpoint = `continents/${propertyType}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getContinentByName = async (propertyType, continentName) => {
    let result = ref(null);

    const callKey = `getContinentByName${locale?.value}${propertyType}${continentName}${userCurrency?.code}`;
    const endpoint = `continent/${propertyType}/${continentName}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || null;
  };

  // @TODO: We don't have an endpoint to search for a continent by ID
  const getContinentById = async (propertyType, continentId) => {
    const callKey = `getContinentById${locale?.value}${propertyType}${continentId}${userCurrency?.code}`;
    const endpoint = `continent/${propertyType}/${continentId}/countries/`;

    const result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    // Remove unnecessary data
    delete result?.value?.data?.countries;

    return result?.value?.data || null;
  };

  const getContinentCountries = async (propertyType, continentId) => {
    let result = ref(null);

    const callKey = `getContinentCountries${locale?.value}${propertyType}${continentId}${userCurrency?.code}`;
    const endpoint = `continent/${propertyType}/${continentId}/countries/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data?.countries || null;
  };

  const getAverageCityPricesByContinent = async (propertyType, continentId) => {
    let result = ref(null);
    const callKey = `getAllContinents${locale?.value}${propertyType}${continentId}${userCurrency?.code}`;
    const endpoint = `continent/${propertyType}/${continentId}/city-prices/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || null;
  };

  return {
    getAllContinents,
    getContinentByName,
    getContinentById,
    getContinentCountries,
    getAverageCityPricesByContinent,
  };
}
